import React, { useState, useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Diacritics from "diacritic"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AffPes from "../components/pes2021cta"

const ManagerListPage = ({ data }) => {
  const Managers2021 = data.allManager2021.edges
  const Managers2020 = data.allManager.edges
  const [availableManagers, setAvailableManagers] = useState(Managers2021)
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }
  const allFormations = availableManagers.map(manager => manager.node.formation)
  const uniqueFormations = allFormations.filter(onlyUnique).sort()
  const allNationalities = availableManagers.map(
    manager => manager.node.nationality
  )
  const uniqueNationalities = allNationalities.filter(onlyUnique).sort()
  const [formationPick, setFormationPick] = useState("any")
  const [costPick, setCostPick] = useState("any")
  const [nationalityPick, setNationalityPick] = useState("any")
  const queryRef = useRef()
  const [query, setQuery] = useState("")
  const [managers, setManagers] = useState(availableManagers)
  const [year, setYear] = useState(2021)
  const [searchStatus, setSearchStatus] = useState(true)

  useEffect(() => {
    if (year === 2021) {
      setAvailableManagers(Managers2021)
    } else {
      setAvailableManagers(Managers2020)
    }
    if (searchStatus === true) {
      queryRef.current.value = ""
    }
  }, [year])

  useEffect(() => {
    if (query !== "") {
      let filteredManagers = []
      availableManagers.forEach(manager => {
        if (
          Diacritics.clean(manager.node.gameName.toUpperCase()).includes(
            Diacritics.clean(query.toUpperCase())
          )
        ) {
          filteredManagers.push(manager)
        } else if (
          manager.node.realName !== null &&
          Diacritics.clean(manager.node.realName.toUpperCase()).includes(
            Diacritics.clean(query.toUpperCase())
          )
        ) {
          filteredManagers.push(manager)
        }
      })
      setManagers(filteredManagers)
    } else {
      setManagers(availableManagers)
    }
  }, [query])

  useEffect(() => {
    let filteredManagers = availableManagers
    if (formationPick !== "any") {
      filteredManagers = filteredManagers.filter(
        manager => manager.node.formation === formationPick
      )
    }
    if (costPick !== "any") {
      filteredManagers = filteredManagers.filter(
        manager => manager.node.coins <= parseInt(costPick)
      )
    }
    if (nationalityPick !== "any") {
      filteredManagers = filteredManagers.filter(
        manager => manager.node.nationality === nationalityPick
      )
    }
    setManagers(filteredManagers)
  }, [availableManagers, formationPick, costPick, nationalityPick])

  return (
    <Layout>
      <SEO
        title="Manager List"
        description="Here is the list of available managers in PES 2021, along with Formation, Offensive and Defensive Tactics, Management Skills and Cost. Pick the most suitable Manager in the database for you."
      />
      <div className="container">
        <h1>List of Available Managers in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Manager List</li>
        </ul>
        <p>
          Hiring a good manager in PES myClub is important because, unlike other
          game modes, you can't assign a random formation to your myClub team.
          The best managers are always very expensive (costing you hundreds of
          coins) and are only possible to hire with GP after you have already
          played (or simulated) hundreds of games.
        </p>
        <p>
          If you're looking for the{" "}
          <Link to="/best-managers/">
            Best Managers in the game, have a look at this page.
          </Link>
        </p>
        <p>
          The list of Managers in PES 2021 is always changing, reflecting
          real-life events (such as a new manager joining a club, or changing
          formations mid-season). Also, keep in mind that you won't find every
          manager available at all times.
        </p>
        <p>
          Konami refreshes the list of available managers to you on a daily
          basis (every 8 hours, as a general rule), so keep checking the
          CONTRACT / MANAGERS section.
        </p>
        <p>
          On the last day of each month, the list of available managers is
          exceptionally refreshed every time you visit the "Hire New Managers"
          screen - this is usually the best day to grab the manager you have
          been waiting for!
        </p>
        <p>
          If you would like to use multiple formations, don't forget that you
          can have more than one manager in-game - you can read my{" "}
          <Link to="/signing-managers/">Assistant Coaches</Link> section to
          learn more.
        </p>
      </div>
      <AffPes />
      <div className="container">
        <h2 id="manager-list">
          {availableManagers.length} Managers{" "}
          {year === 2021 ? "for PES 2021" : "for PES 2020"}
        </h2>
        <p>
          Here is my Manager database. You may notice that some managers appear
          more than once; they may have moved on to other clubs or used
          different formations throughout the season.
        </p>
        <p>
          I will add more in the upcoming days and make an effort to keep this
          updated with new managers I come across.
        </p>
        <div className="filter-tabs">
          <div>
            <button
              onClick={e => setSearchStatus(true)}
              className={searchStatus ? "btn active" : "btn inactive"}
            >
              <FontAwesomeIcon icon="search" /> Search
            </button>
            <button
              onClick={e => setSearchStatus(false)}
              className={searchStatus ? "btn inactive" : "btn active"}
            >
              <FontAwesomeIcon icon="filter" /> Filters
            </button>
          </div>
          <div>
            <span className="custom-dropdown">
              <label htmlFor="year" id="year-label">
                Year
              </label>
              <select
                onChange={e => setYear(parseInt(e.target.value))}
                id="year"
              >
                <option value="2021">2021</option>
                <option value="2020">2020</option>
              </select>
            </span>
          </div>
        </div>
        {searchStatus ? (
          <div className="search-field">
            <label htmlFor="search">Search</label>
            <input
              type="text"
              id="search"
              placeholder="Real name or Game Name"
              onChange={e => setQuery(e.target.value)}
              ref={queryRef}
            />
          </div>
        ) : (
          <div className="filter-grid">
            <div className="custom-filter">
              <label htmlFor="formation">Formation</label>
              <span className="custom-dropdown">
                <select
                  onChange={e => setFormationPick(e.target.value)}
                  id="formation"
                >
                  <option value="any">Any formation</option>
                  {uniqueFormations.map(formation => (
                    <option key={formation} value={formation}>
                      {formation}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className="custom-filter">
              <label htmlFor="cost">Cost</label>
              <span className="custom-dropdown">
                <select onChange={e => setCostPick(e.target.value)} id="cost">
                  <option value="any">Any range</option>
                  <option value="550">Up to 550 coins</option>
                  <option value="450">Up to 450 coins</option>
                  <option value="350">Up to 350 coins</option>
                </select>
              </span>
            </div>
            <div className="custom-filter">
              <label htmlFor="nationality">Nationality</label>
              <span className="custom-dropdown">
                <select
                  onChange={e => setNationalityPick(e.target.value)}
                  id="nationality"
                >
                  <option value="any">Any nationality</option>
                  {uniqueNationalities.map(nationality => (
                    <option key={nationality} value={nationality}>
                      {nationality}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>
        )}
        <p>
          <small>
            {managers.length === 0
              ? `No Managers in the list fit that criteria. Please change the parameters.`
              : managers.length === 1
              ? `Currenty showing ${managers.length} manager.`
              : `Currenty showing ${managers.length} managers.`}
          </small>
        </p>
        <ul className="grid-list">
          {managers.map((manager, index) => (
            <li key={index}>
              <Link
                to={`/managers/${year === 2021 ? "2021/" : "2020/"}${
                  manager.node.slug
                }/`}
              >
                {manager.node.gameName}
              </Link>
            </li>
          ))}
        </ul>
        <h2>Frequently Asked Questions (regarding Managers)</h2>
        <h3>What are "Management Skills"?</h3>
        <p>
          Each player has a different cost, based on his ability. A Black Ball
          costs more, especially if he has a very high Overall. If the Total
          Costs of the players in your Squad is higher than your manager's
          Managemet Skills, you are advised to switch to cheaper players - you
          can still choose to use your superstars, but your team's Team Spirit
          will decrease dramatically.
        </p>
        <p>
          Expensive managers usually come with a very high number of Management
          Skills, and you can still increase this value with{" "}
          <Link to="/manager-upgrades/">Management Skill Boost items</Link>.
        </p>
        <h3>What does "Adaptability" mean?</h3>
        <p>
          The greater a manager's Adaptability, the higher his initial
          Familiarity level and the faster that level will grow (maximum is
          120%). Familiarity will help in increasing Team Spirit.
        </p>
        <p>
          Familiarity can actually drop as well. If you constantly use a manager
          as an Assistant Coach, this number will decrease. If he becomes your
          main manager again, Familiarity will go up once more.
        </p>
        <p>
          You can quickly boost your Familiarity level by using{" "}
          <Link to="/manager-upgrades/">Familiarity Boost items</Link>.
        </p>
        <h3>
          What does "Support Range", "Defensive Line" and "Compactness" mean?
        </h3>
        <p>
          You will see that each manager has a number next to each one of these
          3 attributes, which can go from 1 to 10. "Support Range" concerns your
          manager's Attacking Instructions, while "Defensive Line" and
          "Compactness" affects the way your team defends.
        </p>
        <h4>Support Range</h4>
        <p>
          As far as "Support Range" is concerned, a lower number is desired if
          you enjoy a possession-based style with short passes, since players
          will stay closer together.
        </p>
        <p>
          A higher number is desired if you play long balls and exploit
          counter-attacks, since teammates will keep their distance between
          eachother.
        </p>
        <h4>Defensive Line</h4>
        <p>
          When it comes to the "Defensive Line" attribute, a low number will
          make your team drop deeper, staying closer to the box, making it
          harder for the opponent to surprise you on the counter.
        </p>
        <p>
          A high number will keep your players up on the pitch, applying more
          pressure but staying more vulnerable to quick counter attacks.
        </p>
        <h4>Compactness</h4>
        <p>
          As for "Compactness", a low number will keep the team well spread out
          and wide, while a high value will keep your team closer together and
          more central in the pitch. The latter is a better option to prevent
          attacks from the middle.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allManager(sort: { fields: gameName, order: ASC }) {
      edges {
        node {
          slug
          gameName
          realName
          coins
          formation
          nationality
        }
      }
    }
    allManager2021(sort: { fields: gameName, order: ASC }) {
      edges {
        node {
          slug
          gameName
          realName
          coins
          formation
          nationality
        }
      }
    }
  }
`

export default ManagerListPage
